.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@import url('https://fonts.googleapis.com/css2?family=Arima:wght@400&display=swap');

@font-face {
  font-family: 'tangier';
  src: url('./font/tangier.woff2') format('woff2');
  /* Tambahkan format font lain jika diperlukan (e.g., woff, ttf) */
  font-weight: normal;
  font-style: normal;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-frames-step {
  animation: step2 1s steps(2) infinite
}

.animate-frames-step-reverse {
  animation: step2 1s steps(2) infinite reverse
}

@keyframes step2 {
  0% {
      transform: rotate(0)
  }

  to {
      transform: rotate(10deg)
  }
}
.component {
  transition: transform 0.5s ease; /* Add smooth transition for the transform property */
}

.component-enter,
.component-exit {
  transform: translateY(100%);
}

.component-enter-active,
.component-exit-active {
  transform: translateY(0);
}


.bottom-navigation {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}

.scrollable-icons {
  display: flex;
  overflow-x: auto; /* Add this property for horizontal scrolling */
  scroll-behavior: smooth; /* Enable smooth scrolling */
  box-sizing: border-box; /* Include padding and border in the width */
  white-space: nowrap; /* Prevent wrapping to the next line */
  width: 451px;
}

.scrollable-icons::-webkit-scrollbar {
  display: none;
}

.icon {
  flex: 0 0 auto;
  height: 60px; /* Adjust the height of each icon as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  @apply w-1/3
}

@keyframes collapseAnimation {
  from {
    height: 100vh;
  }
  to {
    height: 0vh;
  }
}
